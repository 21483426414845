import { useParams } from 'react-router-dom';
import { useFetchResource } from '../../../utils/api_request_helpers';
import { CoachingApiEndpoints } from '../../api_endpoints';
import UserRecordSection from './user_record_section';
import {
  AiTool,
  HaikuCheckin,
  JournalReflection,
  MoodArtCheckin,
} from '../../../types/coaching/ai_tool';
import HaikuCheckinRecord from './reflections/haiku_checkin_record';
import JournalReflectionEntry from './reflections/journal_reflection_record';
import MoodArtCheckinRecord from './reflections/mood_art_record';

interface AiToolEntry {
  type: string;
  item: AiTool;
}

export default function Reflections() {
  const urlParams = useParams<{ userId: string }>();
  const userId = urlParams.userId!;

  const [loading, , reflections] = useFetchResource<AiToolEntry[]>(
    CoachingApiEndpoints.userReflections(userId),
  );

  const renderToolEntry = (entry: AiToolEntry) => {
    if (entry.type === 'Reflection') {
      return (
        <JournalReflectionEntry reflection={entry.item as JournalReflection} />
      );
    }

    if (entry.type === 'HaikuCheckin') {
      return <HaikuCheckinRecord haikuCheckin={entry.item as HaikuCheckin} />;
    }

    if (entry.type === 'MoodCheckin') {
      return (
        <MoodArtCheckinRecord moodArtCheckin={entry.item as MoodArtCheckin} />
      );
    }
  };

  return (
    <UserRecordSection title="User Reflections" loading={loading}>
      <ul className="space-y-3">
        {reflections?.map((reflection) => (
          <li
            key={reflection.item.id + reflection.type}
            className="bg-white shadow-md overflow-hidden rounded-md px-6 py-4">
            {renderToolEntry(reflection)}
          </li>
        ))}
      </ul>
    </UserRecordSection>
  );
}
