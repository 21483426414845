import { HaikuCheckin } from '../../../../types/coaching/ai_tool';
import HumanReadableDate from '../../human_readable_date';

export default function HaikuCheckinRecord({
  haikuCheckin,
}: {
  haikuCheckin: HaikuCheckin;
}) {
  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">Mood Poem</div>
        <div>
          <HumanReadableDate isoValue={haikuCheckin.created_at} />
        </div>
      </div>

      <div className="mt-2 space-y-3">
        <div className="text-md">{haikuCheckin.content}</div>

        <div className="mt-2 space-y-2 text-sm italic">
          <div className="font-bold">AI Poem</div>
          <div>{haikuCheckin.ai_poem}</div>
        </div>
      </div>
    </div>
  );
}
