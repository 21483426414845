import { MoodArtCheckin } from '../../../../types/coaching/ai_tool';
import HumanReadableDate from '../../human_readable_date';

export default function MoodArtCheckinRecord({
  moodArtCheckin,
}: {
  moodArtCheckin: MoodArtCheckin;
}) {
  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">Mood Art</div>
        <div>
          <HumanReadableDate isoValue={moodArtCheckin.created_at} />
        </div>
      </div>

      <div className="mt-2 space-y-3">
        <div className="text-md">{moodArtCheckin.content}</div>

        <div className="mt-2 space-y-2 text-sm italic">
          <div className="font-bold italic">AI Generated Image</div>
          <img
            src={moodArtCheckin.mood_art.image_uri}
            alt="Mood Art"
            height={200}
            width={200}
          />
        </div>
      </div>
    </div>
  );
}
