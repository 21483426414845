import { useState } from 'react';
import { JournalReflection } from '../../../../types/coaching/ai_tool';
import HumanReadableDate from '../../human_readable_date';

export default function JournalReflectionEntry({
  reflection,
}: {
  reflection: JournalReflection;
}) {
  const [showPrompt, setshowPrompt] = useState(false);

  return (
    <div>
      <div className="flex justify-between">
        <div className="font-bold text-lg">Reflection Journal</div>
        <div>
          <HumanReadableDate isoValue={reflection.created_at} />
        </div>
      </div>

      <div className="mt-2">
        {reflection.reflection_prompt ? (
          <span
            className="text-blue-400 underline text-sm cursor-pointer"
            onClick={() => {
              setshowPrompt(!showPrompt);
            }}>
            {`Prompt ${reflection.reflection_prompt.id}`}
          </span>
        ) : (
          <span className="font-normal text-sm italic"> No Prompt </span>
        )}
      </div>

      {showPrompt && (
        <div className="my-3">
          <div>{reflection.reflection_prompt.title}</div>
          <div>{reflection.reflection_prompt.subtitle}</div>
        </div>
      )}

      <div className="mt-2 space-y-3">
        <div className="text-md">{reflection.content}</div>

        <div className="mt-2 space-y-2 text-sm italic">
          <div className="font-bold">AI Summary</div>
          <div>{reflection.ai_summary}</div>
        </div>

        {reflection.insight_type && (
          <div className="mt-2 text-sm italic space-y-2">
            <div className="font-bold">Insight</div>
            <div>{reflection.insight_type}</div>
            <div>{reflection.insight}</div>
          </div>
        )}
      </div>
    </div>
  );
}
